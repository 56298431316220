import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from 'store';
import {
    LeftPanelKeys,
    resetLeftPanel,
    selectIsSinglePropertyViewVisible,
    setActiveLeftPanel,
} from './leftPanelSlice';
import { selectOpenPolygonEditor } from './polygonsLayerSlice';
import { clearPresentation } from './presentationSlice';

interface SinglePropertyViewState {
    value: {
        propertyId: string;
        activeTabKey: string;
        editPropertyView: boolean;
    };
}

const initialState: SinglePropertyViewState = {
    value: { propertyId: '', activeTabKey: 'property', editPropertyView: false },
};

export const setSinglePropertyView =
    (visible: boolean) => (dispatch: AppDispatch, getState: () => RootState) => {
        const singlePropertyViewVisible = selectIsSinglePropertyViewVisible(getState());
        const isPolygonEditorOpen = selectOpenPolygonEditor(getState());
        if (visible) {
            dispatch(setActiveLeftPanel(LeftPanelKeys.SinglePropertyView));
        } else if (singlePropertyViewVisible && !isPolygonEditorOpen) {
            dispatch(resetLeftPanel());
        }
    };

export const singlePropertyViewSlice = createSlice({
    name: 'singlePropertyView',
    initialState,
    reducers: {
        setSelectedPropertyId(state, action: PayloadAction<string>) {
            state.value.propertyId = action.payload;
        },
        setActiveTabKey(state, action: PayloadAction<string>) {
            state.value.activeTabKey = action.payload;
        },
        setEditPropertyView(state, action: PayloadAction<boolean>) {
            state.value.editPropertyView = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(clearPresentation, () => initialState);
    },
});

export const { setSelectedPropertyId, setActiveTabKey, setEditPropertyView } =
    singlePropertyViewSlice.actions;

export const selectSelectedPropertyId = (state: RootState): string => {
    return state.singlePropertyView?.value?.propertyId;
};

export const selectActiveTabKey = (state: RootState): string => {
    return state.singlePropertyView?.value?.activeTabKey;
};

export const selectEditPropertyView = (state: RootState): boolean => {
    return state.singlePropertyView?.value?.editPropertyView;
};

export default singlePropertyViewSlice.reducer;
