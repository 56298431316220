import { useCallback, useEffect, useRef } from 'react';
import Handles from '@arcgis/core/core/Handles';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import TileLayer from '@arcgis/core/layers/TileLayer';
import FeatureLayerView from '@arcgis/core/views/layers/FeatureLayerView';
import { App } from '@jll/react-ui-components';
import { get, includes, isEmpty, uniqBy } from 'lodash';

import OsmHighlightPinMenu from 'components/mapContextMenu/OsmHighlightPinMenu';
import {
    INDUSTRIAL_SUBMARKETS_LAYER_ID,
    OFFICE_SUBMARKETS_LAYER_ID,
} from 'constants/map.constants';
import {
    DEV_PIPELINE_PINS_ID,
    findMarketSphereIdFromBlackbirdId,
    getDevPipelineSourceByLayerId,
} from 'helpers/devPipelineHelper';
import { isOsmSceneLayer, OSM_PINS_LAYER_ID } from 'helpers/osmBuildingLayerHelper';
import { allHighlightMetadataForKey } from 'helpers/osmHighlightSetHelper';
import {
    PARCEL_OUTLINES_LAYER_ID,
    queryParcelOutlinesLayer,
} from 'helpers/parcelOutlinesLayerHelper';
import {
    queryBuildingEditsLayer,
    searchMarketSphereIdByBlackbirdId,
} from 'helpers/polygonEditorHelper';
import { BUILDING_EDITS_PINS_LAYER_ID } from 'helpers/polygonEditorPinHelper';
import { highlightAnnotation } from 'helpers/quickLayerHelper';
import { MISSING_POLYGONS_PINS_LAYER, SEARCH_PINS_LAYER } from 'helpers/searchHelper';
import { MapContextData, useMap } from 'hooks/MapProvider';
import { AppDispatch, AppThunk, RootState } from 'store';
import {
    rejectMatch,
    selectMatch,
    selectMatchCsvInfo,
    selectMatchModeState,
    selectOsmHighlightInMatchMode,
    startMatch,
} from 'store/csvHighlightMatchSlice';
import { setSelectedCsvLayer } from 'store/csvLayerSlice';
import {
    LeftPanelKeys,
    selectIsSinglePropertyViewVisible,
    setActiveLeftPanel,
} from 'store/leftPanelSlice';
import {
    isAllPropertiesChecked,
    moveCsvLayerPin,
    moveOsmHighlightPin,
    OsmLookupItem,
    osmLookupItemForKey,
    selectCheckedKeyLookup,
    selectLibraryItemsByKey,
    selectOsmHighlightSetLookup,
} from 'store/libraryItemSlice';
import {
    loadOsmMappingForOsmIds,
    OsmMatchingProgressState,
    selectMappingLookupByOsmId,
    selectMatching,
    setDevPipelineMarketSphereIds,
    setMatching,
} from 'store/marketSphereOsmMappingSlice';
import {
    CsvPinReference,
    exitMove,
    HighlightPinReference,
    selectActiveMovePin,
    selectCsvPin,
    selectOsmPin,
} from 'store/movePinSlice';
import {
    cleanSelectedGraphics,
    setSelectedGraphics,
    setSelectedLayerId,
} from 'store/polygonsLayerSlice';
import {
    selectedAnnotation,
    selectedAnnotationMode,
    setAnnotationMode,
    setHighlightedAnnotationKey,
    setSelectedAnnotation,
    setSelectedQuickLayer,
} from 'store/quickLayerSlice';
import { RightPanelKeys, setActiveRightPanel } from 'store/rightPanelSlice';
import { setSelectedResultItem } from 'store/searchSlice';
import {
    selectSelectedPropertyId,
    setSelectedPropertyId,
    setSinglePropertyView,
} from 'store/singlePropertyViewSlice';
import { selectSelectedSubmarket, setSelectedSubmarket } from 'store/submarketsSlice';
import {
    clearSelectedT2HBuilding,
    selectSelectedBuilding,
    setSelectedT2HBuilding,
} from 'store/tableHighlightSetSlice';
import { useAppDispatch, useAppSelector } from 'types/hooks';
import { primaryLibraryItemKey, QuickLayerTreeItem } from 'types/Layers/LibraryLayerTreeItem';
import { AnnotationMetadata } from 'types/Layers/QuickLayerMetadata';
import config from 'utils/config';
import { findMapLayer } from 'utils/esri/findMapLayerUtils';
import { getLayerView } from 'utils/esri/layerViewUtils';
import {
    addHighlightBuilding,
    addHighlightByLayerId,
    removeBuildingHighlights,
    removeHighlightBuilding,
} from '../HighlighSets/buildingSelectionStyleHelpers';
import MatchCsvOsmHighlightMenu from '../mapContextMenu/MatchCsvOsmHighlightMenu';

export const SEARCH_RESULTS_PINS_MAP_CLICK_EVENT_KEY = 'search-result-pins-map-click';
export const DEV_PIPELINE_MAP_CLICK_EVENT_KEY = 'dev-pipeline-map-click';
export const OSM_BUILDINGS_MAP_CLICK_EVENT_KEY = 'osm-map-click';
export const T2H_CSV_PIN_CLICK_EVENT_KEY = 't2h-csv-pin-click';
export const T2H_MATCHED_BUILDING_CLICK_EVENT_KEY = 't2h-matched-building-click';
export const T2H_UNMATCHED_BUILDING_CLICK_EVENT_KEY = 't2h-unmatched-building-click';
export const BUILDING_EDITS_LAYER_MAP_CLICK_EVENT_KEY = 'buildings-edits-layer-map-click';
export const BUILDING_EDITS_PINS_LAYER_MAP_CLICK_EVENT_KEY = 'buildings-edits-pins-layer-map-click';

export interface ViewClickHandlerContext {
    view: __esri.SceneView | undefined;
    showMapContextMenu: MapContextData['showMapContextMenu'];
    app: ReturnType<typeof App.useApp>;
}

export type ViewClickHandler = (
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent,
    extra: ViewClickHandlerContext
) => boolean;

type ViewClickHandlerThunk = (
    thunkProps: { dispatch: AppDispatch; getState: () => RootState },
    ...args: Parameters<ViewClickHandler>
) => boolean;

function createUseClickHandler(action: ViewClickHandlerThunk): () => ViewClickHandler {
    function clickActionWrapper(...args: Parameters<ViewClickHandler>): AppThunk<boolean> {
        return (dispatch, getState) => {
            return action({ dispatch, getState }, ...args);
        };
    }

    return function useClickHandlerWrapper() {
        const dispatch = useAppDispatch();

        return useCallback((...args) => dispatch(clickActionWrapper(...args)), [dispatch]);
    };
}

let highlightHandler: __esri.Handle;

export const useCsvLayerClickHandler = (): ((
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent
) => boolean) => {
    const dispatch = useAppDispatch();
    const matchMode = useAppSelector(selectMatchModeState);
    const isMatchModeEnabled = matchMode === 'ENABLED';
    return useCallback(
        (results, event) => {
            const firstResult = results.results[0];
            if (
                event.button === 0 &&
                firstResult &&
                firstResult.type === 'graphic' &&
                firstResult.layer.id.startsWith('CsvLayer')
            ) {
                dispatch(
                    setSelectedCsvLayer({
                        layerId: firstResult.layer.id,
                        graphicId: firstResult.graphic.getObjectId(),
                    })
                );

                !isMatchModeEnabled && dispatch(setActiveLeftPanel(LeftPanelKeys.CsvLayerData));
                return true;
            }
            return false;
        },
        [dispatch, isMatchModeEnabled]
    );
};

export const useCsvHighlightMatchClickHandler = (): ((
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent
) => boolean) => {
    const { showMapContextMenu } = useMap();
    const dispatch = useAppDispatch();
    const csvHighlightMatchState = useRef<ReturnType<typeof selectMatchCsvInfo>>({
        state: 'INACTIVE',
    });
    csvHighlightMatchState.current = useAppSelector(selectMatchCsvInfo);
    const matchingMode = useAppSelector(selectMatching);
    const selectedBuilding = useAppSelector(selectSelectedBuilding);
    const matchModeCsvHighlightId = useRef<ReturnType<typeof selectOsmHighlightInMatchMode>>();
    matchModeCsvHighlightId.current = useAppSelector(selectOsmHighlightInMatchMode);

    const checkedKeyLookup = useRef<ReturnType<typeof selectCheckedKeyLookup>>();
    checkedKeyLookup.current = useAppSelector(selectCheckedKeyLookup);

    const { candidateMatchOsmId } = csvHighlightMatchState.current;
    const libraryItemsByKey = useRef<ReturnType<typeof selectLibraryItemsByKey>>({});
    libraryItemsByKey.current = useAppSelector(selectLibraryItemsByKey);

    const osmLookup = useRef<Record<string, OsmLookupItem[] | undefined>>();
    osmLookup.current = useAppSelector(selectOsmHighlightSetLookup);
    const handler = useRef<Handles>(new Handles());

    const removeHandlers = () => {
        handler.current.remove(T2H_MATCHED_BUILDING_CLICK_EVENT_KEY);
        handler.current.remove(T2H_CSV_PIN_CLICK_EVENT_KEY);
        handler.current.remove(T2H_UNMATCHED_BUILDING_CLICK_EVENT_KEY);
    };

    const addMatchedHandlers = async (osmId: number) => {
        const pinHandler = await addHighlightBuilding(
            'osm-pins',
            osmId,
            T2H_CSV_PIN_CLICK_EVENT_KEY
        );
        const buildingHandler = await addHighlightBuilding(
            'osm',
            osmId,
            T2H_MATCHED_BUILDING_CLICK_EVENT_KEY
        );
        if (pinHandler && buildingHandler) {
            handler.current.add(pinHandler, T2H_CSV_PIN_CLICK_EVENT_KEY);
            handler.current.add(buildingHandler, T2H_MATCHED_BUILDING_CLICK_EVENT_KEY);
        }
    };

    const addUnmatchedHandlers = (layerId: string, graphic: Graphic) => {
        addHighlightByLayerId(layerId, [graphic], T2H_UNMATCHED_BUILDING_CLICK_EVENT_KEY).then(
            (unmatchedHandler) => {
                if (unmatchedHandler) {
                    handler.current.add(unmatchedHandler, T2H_UNMATCHED_BUILDING_CLICK_EVENT_KEY);
                }
            }
        );
    };

    useEffect(() => {
        if (matchingMode && candidateMatchOsmId) {
            addMatchedHandlers(candidateMatchOsmId);
        } else if (!selectedBuilding) {
            removeHandlers();
        }
    }, [matchingMode, candidateMatchOsmId, selectedBuilding]);

    return useCallback(
        (results, position) => {
            removeHandlers();
            removeBuildingHighlights();
            const firstResult = results.results[0];
            if (
                firstResult &&
                firstResult.type === 'graphic' &&
                !isOsmSceneLayer(firstResult.layer.id)
            ) {
                const graphic = firstResult.graphic;
                const osmId = graphic.attributes.OSMID || graphic.attributes.osmId;
                const possibleLibraryKeyId = graphic.attributes.libraryItemKey as unknown;
                const libraryKeyId =
                    typeof possibleLibraryKeyId === 'string' ? possibleLibraryKeyId : undefined;
                const primaryItem =
                    libraryKeyId && libraryItemsByKey.current[primaryLibraryItemKey(libraryKeyId)];

                let item: OsmLookupItem | undefined;
                if (primaryItem) {
                    // Prefer to lookup the entry directly by library item key
                    item = osmLookupItemForKey(
                        primaryItem,
                        libraryKeyId,
                        !!checkedKeyLookup.current?.[libraryKeyId]
                    );
                } else {
                    // The last checked entry is the one that will be currently visualized on the map
                    item = osmLookup.current?.[osmId]?.findLast(
                        (entry) => entry.checked && entry.csvLayerMetadata
                    );
                }

                dispatch(clearSelectedT2HBuilding());
                if (
                    ['SELECT_MATCH', 'CONFIRM_MATCH'].includes(
                        csvHighlightMatchState.current.state
                    ) &&
                    osmId
                ) {
                    dispatch(selectMatch({ osmId: graphic.attributes.OSMID }));
                    showMapContextMenu([position.x, position.y], MatchCsvOsmHighlightMenu);
                    return true;
                } else if (
                    firstResult.layer.id.endsWith('-unmatched-pins') &&
                    libraryKeyId &&
                    primaryItem
                ) {
                    if (primaryItem.key === matchModeCsvHighlightId.current) {
                        dispatch(startMatch(String(graphic.attributes.libraryItemKey)));
                        dispatch(setActiveRightPanel(RightPanelKeys.TableToHighlightMatch));
                    } else if (
                        !['SELECT_MATCH', 'CONFIRM_MATCH'].includes(
                            csvHighlightMatchState.current.state
                        )
                    ) {
                        const { primaryMetadata, buildingMetadata } = allHighlightMetadataForKey(
                            libraryKeyId,
                            primaryItem
                        );
                        if (primaryMetadata && buildingMetadata) {
                            dispatch(
                                setSelectedT2HBuilding({
                                    csvLayerMetadata: primaryMetadata.csvLayerMetadata,
                                    building: buildingMetadata,
                                })
                            );
                            addUnmatchedHandlers(firstResult.layer.id, graphic);
                        }
                    }
                    return true;
                } else if (item) {
                    if (item && item.checked && item.csvLayerMetadata != null) {
                        dispatch(
                            setSelectedT2HBuilding({
                                csvLayerMetadata: item.csvLayerMetadata,
                                building: item.building,
                            })
                        );
                        const osmId = item.building?.geometryRef?.id;
                        osmId && addMatchedHandlers(osmId);

                        return true;
                    }
                }
            }

            if (csvHighlightMatchState.current.state === 'CONFIRM_MATCH') {
                dispatch(rejectMatch());
            }

            return false;
        },
        [showMapContextMenu, dispatch]
    );
};

function handleSPV(marketSphereId: string, osmId?: number): AppThunk<boolean> {
    return (dispatch) => {
        dispatch(setSinglePropertyView(!!marketSphereId));
        dispatch(cleanSelectedGraphics());
        if (!marketSphereId) {
            return false;
        }
        dispatch(setSelectedPropertyId(marketSphereId));
        if (osmId) {
            removeBuildingHighlights();
            addHighlightBuilding('osm', osmId, OSM_BUILDINGS_MAP_CLICK_EVENT_KEY);
        }
        return true;
    };
}

function handleOsmClick(
    graphic: Graphic,
    event: __esri.ViewClickEvent,
    showMapContextMenu: MapContextData['showMapContextMenu']
): AppThunk<boolean> {
    return (dispatch, getState) => {
        const state = getState();
        const allPropertiesChecked = isAllPropertiesChecked(state);
        const mappingLookupByOsmId = selectMappingLookupByOsmId(state);
        const matching = selectMatching(state);
        const selectedPropertyId = selectSelectedPropertyId(state);

        const osmId = graphic?.attributes?.OSMID;
        const marketSphereId =
            mappingLookupByOsmId?.[osmId]?.marketSpherePropertyId ??
            graphic?.attributes?.marketSpherePropertyId;

        if (event.button === 2 && osmId) {
            showMapContextMenu([event.x, event.y]);
            dispatch(setSelectedGraphics([graphic]));
            addHighlightBuilding('osm', osmId, OSM_BUILDINGS_MAP_CLICK_EVENT_KEY);
            return true;
        }

        const matchingState = matching?.state;
        if (
            matching !== null &&
            matchingState &&
            ['matching', 'reviewing'].includes(matchingState)
        ) {
            if (!osmId) return false;
            addHighlightBuilding('osm', osmId, OSM_BUILDINGS_MAP_CLICK_EVENT_KEY);
            const selectedPropertyIdAsNumber = Number(selectedPropertyId);
            if (selectedPropertyId && Number.isFinite(selectedPropertyIdAsNumber)) {
                addHighlightBuilding(
                    'missing-pins',
                    selectedPropertyIdAsNumber,
                    SEARCH_RESULTS_PINS_MAP_CLICK_EVENT_KEY
                );
            }
            dispatch(
                setMatching({
                    osmId: osmId,
                    state: 'matching',
                })
            );
            return true;
        }

        if (marketSphereId) {
            return dispatch(handleSPV(marketSphereId, osmId));
        }

        if (!marketSphereId) {
            if (allPropertiesChecked || !osmId) return false;
            document.body.style.cursor = 'wait';
            dispatch(loadOsmMappingForOsmIds([osmId]))
                .unwrap()
                .then((mappings) => {
                    if (!mappings?.length) return false;
                    const msId = mappings[0].marketSpherePropertyId;
                    if (msId) return dispatch(handleSPV(msId.toString(), osmId));
                    else return false;
                })
                .finally(() => {
                    document.body.style.cursor = 'default';
                    return false;
                });
        }
        return !!marketSphereId;
    };
}

function handleDevPipelineClick(
    graphic: Graphic,
    event: __esri.ViewClickEvent,
    showMapContextMenu: MapContextData['showMapContextMenu']
): AppThunk<boolean> {
    return (dispatch) => {
        const marketSphereId = graphic.getAttribute('MarketSpherePropertyId');
        dispatch(setDevPipelineMarketSphereIds([marketSphereId]));
        const bbId = graphic?.getAttribute('BlackbirdId');
        if (!bbId || !marketSphereId) return false;
        const devPipelineLayerId = getDevPipelineSourceByLayerId(graphic.layer.id);
        addHighlightBuilding(devPipelineLayerId, bbId, DEV_PIPELINE_MAP_CLICK_EVENT_KEY);

        if (event.button === 2) {
            showMapContextMenu([event.x, event.y]);
            dispatch(setSelectedGraphics([graphic]));
            return true;
        }

        dispatch(setSinglePropertyView(true));
        dispatch(setSelectedPropertyId(marketSphereId));
        return true;
    };
}

function handleEditedBuildingClick(
    graphic: Graphic,
    event: __esri.ViewClickEvent,
    showMapContextMenu: MapContextData['showMapContextMenu']
): AppThunk<boolean> {
    return (dispatch) => {
        const marketSpherePropertyId = graphic.getAttribute('MarketSpherePropertyId');
        marketSpherePropertyId &&
            addHighlightBuilding(
                'building-edits-layer',
                marketSpherePropertyId,
                BUILDING_EDITS_LAYER_MAP_CLICK_EVENT_KEY
            );
        if (event.button === 2 && marketSpherePropertyId) {
            showMapContextMenu([event.x, event.y]);
            dispatch(setSelectedGraphics([graphic]));
            return true;
        }
        queryBuildingEditsLayer([marketSpherePropertyId]).then((features) => {
            if (!features) return;

            const uniqueFeatures = uniqBy(features, (feature) => feature.attributes['BlackbirdId']);
            dispatch(setSelectedGraphics(uniqueFeatures));

            if (event.button === 2) {
                showMapContextMenu([event.x, event.y]);
            } else if (marketSpherePropertyId) {
                dispatch(setSelectedPropertyId(marketSpherePropertyId));
                dispatch(setSinglePropertyView(true));
            }
        });

        return !!marketSpherePropertyId;
    };
}

export const useBuildingsClickHandler = (): ViewClickHandler => {
    const dispatch = useAppDispatch();
    const matching = useRef<OsmMatchingProgressState | null>();
    matching.current = useAppSelector(selectMatching);
    const isMatchingActive = matching.current !== null && matching.current?.state === 'matching';
    const spvActive = useAppSelector(selectIsSinglePropertyViewVisible);

    useEffect(() => {
        if (!spvActive && !isMatchingActive) {
            dispatch(cleanSelectedGraphics());
            dispatch(setSelectedResultItem(undefined));
            removeBuildingHighlights();
        }
    }, [dispatch, isMatchingActive, spvActive]);

    return useCallback(
        (result, event, { showMapContextMenu }) => {
            const firstResult = result.results[0];
            removeBuildingHighlights();

            if (!firstResult || firstResult.type !== 'graphic') {
                dispatch(setSinglePropertyView(false));
                return false;
            }

            dispatch(setSelectedLayerId(firstResult.layer.id));
            switch (firstResult.layer.id) {
                case 'osm-scene-layer':
                    return dispatch(handleOsmClick(firstResult.graphic, event, showMapContextMenu));
                case 'devpipeline-scene-layer2':
                case 'devpipeline-scene-layer':
                    return dispatch(
                        handleDevPipelineClick(firstResult.graphic, event, showMapContextMenu)
                    );
                case config.buildingEditsLayerItemId:
                    return dispatch(
                        handleEditedBuildingClick(firstResult.graphic, event, showMapContextMenu)
                    );
                default:
                    return false;
            }
        },
        [dispatch]
    );
};

export const usePinsClickHandler = (): ((
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent
) => boolean) => {
    const selectProperty = Number(useAppSelector(selectSelectedPropertyId));
    const dispatch = useAppDispatch();
    const spvActive = useAppSelector(selectIsSinglePropertyViewVisible);
    const highlightedProperty = useRef<number>();
    const { showMapContextMenu } = useMap();

    useEffect(() => {
        if (!spvActive || selectProperty !== highlightedProperty.current) {
            removeHighlightBuilding(
                ['search-result-pins', 'missing-pins'],
                SEARCH_RESULTS_PINS_MAP_CLICK_EVENT_KEY
            );
            highlightedProperty.current = selectProperty;
        }
    }, [spvActive, selectProperty]);

    return useCallback(
        (result: __esri.SceneViewHitTestResult, event) => {
            const firstResult = result.results.at(0);
            if (!firstResult || firstResult.type !== 'graphic') return false;

            const graphic = firstResult.graphic;
            const layerId = graphic.layer.id;
            const buildingType =
                (layerId === SEARCH_PINS_LAYER && 'search-result-pins') ||
                (layerId === MISSING_POLYGONS_PINS_LAYER && 'missing-pins');

            const marketSpherePropertyId = graphic.getAttribute('marketSpherePropertyId');
            if (!marketSpherePropertyId || !buildingType) return false;

            if (event.button === 2) {
                showMapContextMenu([event.x, event.y]);
            }

            dispatch(setSelectedGraphics([graphic]));
            dispatch(setSinglePropertyView(true));
            dispatch(setSelectedPropertyId(marketSpherePropertyId));
            highlightedProperty.current = marketSpherePropertyId;

            addHighlightBuilding(
                buildingType,
                marketSpherePropertyId,
                SEARCH_RESULTS_PINS_MAP_CLICK_EVENT_KEY
            );

            return true;
        },
        [dispatch, showMapContextMenu]
    );
};

export const useHighlightPinLeftClickHandler = createUseClickHandler(
    ({ dispatch, getState }, result, event) => {
        const state = getState();
        const mappingLookupByOsmId = selectMappingLookupByOsmId(state);

        const graphic = result?.results?.[0]?.type === 'graphic' ? result.results[0].graphic : null;

        if (graphic?.layer?.id === OSM_PINS_LAYER_ID && event.button === 0) {
            const osmId = graphic?.attributes?.osmId;
            const marketSphereId =
                mappingLookupByOsmId?.[osmId]?.marketSpherePropertyId ??
                graphic?.attributes?.marketSpherePropertyId;
            return dispatch(handleSPV(marketSphereId, osmId));
        }
        return false;
    }
);

export const useDevPipelineHighlightPinLeftClickHandler = createUseClickHandler(
    ({ dispatch }, result, event) => {
        const graphic = result?.results?.[0]?.type === 'graphic' ? result.results[0].graphic : null;

        if (graphic?.layer?.id === DEV_PIPELINE_PINS_ID && event.button === 0) {
            const blackbirdId = graphic?.attributes?.blackbirdId;
            if (!blackbirdId) return false;
            findMarketSphereIdFromBlackbirdId(blackbirdId).then((marketSphereId) => {
                if (marketSphereId) {
                    dispatch(handleSPV(String(marketSphereId)));
                }
            });
            return true;
        }
        return false;
    }
);

export const useBuildingEditsHighlightPinClickHandler = createUseClickHandler(
    ({ dispatch }, result, event) => {
        removeHighlightBuilding(
            'building-edits-highlight-pin-layer',
            BUILDING_EDITS_PINS_LAYER_MAP_CLICK_EVENT_KEY
        );
        const graphic = result?.results?.[0]?.type === 'graphic' ? result.results[0].graphic : null;

        if (graphic?.layer?.id === BUILDING_EDITS_PINS_LAYER_ID && event.button === 0) {
            const blackbirdId = graphic?.attributes?.blackbirdId;
            if (blackbirdId) {
                searchMarketSphereIdByBlackbirdId(blackbirdId).then((marketSpherePropertyId) => {
                    if (marketSpherePropertyId) {
                        dispatch(handleSPV(marketSpherePropertyId));

                        addHighlightBuilding(
                            'building-edits-highlight-pin-layer',
                            blackbirdId,
                            BUILDING_EDITS_PINS_LAYER_MAP_CLICK_EVENT_KEY
                        );

                        return true;
                    }
                    return false;
                });
            }
        }
        return false;
    }
);

export const useSubmarketClickHandler = (): ((
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent
) => boolean) => {
    const dispatch = useAppDispatch();
    const selectedSubmarket = useAppSelector(selectSelectedSubmarket);

    useEffect(() => {
        if (!selectedSubmarket && highlightHandler) {
            highlightHandler.remove();
        }
    }, [selectedSubmarket]);

    return useCallback(
        (result, event) => {
            if (event.button !== 0) return false;
            const firstResult = result.results[0];
            if (
                !firstResult ||
                firstResult.type !== 'graphic' ||
                !includes(
                    [OFFICE_SUBMARKETS_LAYER_ID, INDUSTRIAL_SUBMARKETS_LAYER_ID],
                    firstResult.layer.id
                )
            ) {
                return false;
            }

            const graphic = firstResult.graphic;
            const marketCode = graphic.getAttribute('MarketCode');
            const propertyType = graphic.getAttribute('PropertyType');
            const submarketCode = graphic.getAttribute('SubmarketCode');
            const submarket = graphic.getAttribute('Submarket');

            if (!marketCode || !submarketCode || !propertyType) return false;
            dispatch(setSelectedSubmarket({ marketCode, propertyType, submarket, submarketCode }));
            dispatch(setActiveRightPanel(RightPanelKeys.SubmarketStatistics));

            if (graphic.layer) {
                getLayerView(graphic.layer).then((layerView) => {
                    if (layerView) {
                        if (highlightHandler) highlightHandler.remove();
                        highlightHandler = (layerView as FeatureLayerView).highlight(graphic);
                    }
                });
            }

            return true;
        },
        [dispatch]
    );
};

export const useParcelOutlinesClickHandler = (): ViewClickHandler => {
    return useCallback((result, event, { view }) => {
        if (event.button !== 0 || !isEmpty(result?.results) || !view) return false;

        const tileLayer = findMapLayer(PARCEL_OUTLINES_LAYER_ID) as TileLayer;
        if (!tileLayer) return false;

        queryParcelOutlinesLayer(result.ground.mapPoint).then((features) => {
            if (isEmpty(features)) return false;
            view.popup.open({
                features: features,
                title: features[0].attributes.title,
                location: result.ground.mapPoint,
            });
            return true;
        });
        return false;
    }, []);
};

export const useQuickLayerClickHandler = (): ((
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent
) => boolean) => {
    const dispatch = useAppDispatch();
    const annotationMode = useAppSelector(selectedAnnotationMode);
    const currentAnnotation = useAppSelector(selectedAnnotation);
    const libraryItemsByKey = useRef<ReturnType<typeof selectLibraryItemsByKey>>({});
    libraryItemsByKey.current = useAppSelector(selectLibraryItemsByKey);

    const highlightHandlerRef = useRef<IHandle>();

    const removeHighlightHandler = useCallback(() => {
        if (highlightHandlerRef.current) {
            highlightHandlerRef.current.remove();
            highlightHandlerRef.current = undefined;
        }
    }, []);

    useEffect(() => {
        if (!currentAnnotation) {
            removeHighlightHandler();
        }
    }, [removeHighlightHandler, currentAnnotation]);

    return useCallback(
        (result, event) => {
            const hitResults = result?.results;
            if (isEmpty(hitResults)) {
                removeHighlightHandler();
                return false;
            }
            if (hitResults[0]?.type === 'graphic') {
                const graphic = hitResults[0]?.graphic;
                if (event.button !== 2 && graphic) {
                    removeHighlightHandler();
                    const graphicKey = get(graphic, 'key') ?? graphic?.getAttribute?.('key');

                    if (!graphicKey) return false;

                    const libraryItem = libraryItemsByKey.current[graphicKey] as QuickLayerTreeItem;
                    if (!libraryItem) return false;

                    const style = (libraryItem.metadata as AnnotationMetadata)?.style;

                    const layer = graphic.layer as FeatureLayer;
                    if (!layer) return false;

                    layer
                        .queryFeatures({
                            where: `key = '${graphicKey}'`,
                            returnGeometry: true,
                        })
                        .then((features) => {
                            if (!features?.features?.length) return;
                            const feature = features.features[0];

                            feature.set('key', graphicKey);
                            feature.set('style', style);
                            dispatch(setSelectedAnnotation(feature));

                            const parentItem =
                                libraryItem.ownerId &&
                                (libraryItemsByKey.current[
                                    libraryItem.ownerId
                                ] as QuickLayerTreeItem);
                            if (parentItem) dispatch(setSelectedQuickLayer(parentItem));

                            if (annotationMode !== 'edit' && annotationMode !== 'create') {
                                dispatch(setAnnotationMode('view'));
                            }

                            dispatch(setActiveRightPanel(RightPanelKeys.QuickLayer));

                            dispatch(setHighlightedAnnotationKey(libraryItem.key));

                            highlightAnnotation(feature, layer).then((handler) => {
                                highlightHandlerRef.current = handler;
                            });
                            return true;
                        });
                }
            }

            return false;
        },
        [annotationMode, removeHighlightHandler, dispatch]
    );
};

function closestHitTestResult(
    result: __esri.SceneViewHitTestResult | undefined
): Point | undefined {
    if (!result) {
        return undefined;
    }

    // Prefer feature matches over ground matches.
    // The results array is sorted by distance,
    // so we can use the first one that has a location.
    const closestFeatureResult = result.results.find((result) => result.mapPoint);
    if (closestFeatureResult) {
        return closestFeatureResult.mapPoint;
    }

    // Fallback to ground mapPoint if available
    return result.ground.mapPoint ?? undefined;
}

function moveOsmPin(
    result: __esri.SceneViewHitTestResult,
    pinReference: HighlightPinReference,
    messageReference: string,
    { view, app: { message } }: ViewClickHandlerContext
): AppThunk<void> {
    return async (dispatch) => {
        if (!view) return;

        try {
            const coordinates = closestHitTestResult(result);
            const layer = view.map.findLayerById(pinReference.layerId) as FeatureLayer;
            const selectedGraphicSet = await layer.queryFeatures({
                objectIds: [pinReference.objectId],
            });
            const selectedGraphic = selectedGraphicSet.features[0];

            if (!coordinates || !selectedGraphic) {
                message.destroy(messageReference);
                message.warning('Pin move cancelled');
                return;
            }

            const updatedGeometry = new Point({
                x: coordinates.x,
                y: coordinates.y,
                spatialReference: coordinates.spatialReference,
            });

            selectedGraphic.geometry = updatedGeometry;

            const pinsLayer = view?.map.findLayerById(OSM_PINS_LAYER_ID);
            if (!(pinsLayer instanceof FeatureLayer)) {
                throw TypeError(
                    'The `OSM_PINS_LAYER_ID` layer is not the expected type: FeatureLayer'
                );
            }

            const edits = {
                updateFeatures: [selectedGraphic],
            };

            await pinsLayer.applyEdits(edits);

            dispatch(
                moveOsmHighlightPin({
                    highlightLayerKey: selectedGraphic.attributes.highlightLayerKey,
                    highlightItemKey: selectedGraphic.attributes.libraryItemKey,
                    position: {
                        longitude: updatedGeometry.longitude,
                        latitude: updatedGeometry.latitude,
                    },
                })
            );

            message.destroy(messageReference);
            message.success(
                `Pin from Highlight set entry ${pinReference.displayName} successfully moved`
            );
        } catch (e) {
            message.destroy(messageReference);
            message.error('An error occurred moving the pin');
            throw e;
        } finally {
            dispatch(exitMove());
        }
    };
}

function moveCsvPin(
    result: __esri.SceneViewHitTestResult,
    pinReference: CsvPinReference,
    messageReference: string,
    { view, app: { message } }: ViewClickHandlerContext
): AppThunk<void> {
    return async (dispatch) => {
        if (!view) return;

        try {
            const coordinates = closestHitTestResult(result);

            if (!coordinates) {
                message.destroy(messageReference);
                message.warning('Pin move cancelled');
                return;
            }

            dispatch(
                moveCsvLayerPin({
                    key: pinReference.csvLayerTreeItemKey,
                    index: pinReference.index,
                    position: {
                        longitude: coordinates.longitude,
                        latitude: coordinates.latitude,
                    },
                })
            );

            message.destroy(messageReference);
            message.success(
                `Pin from Spreadsheet Layer entry ${pinReference.index} successfully moved`
            );
        } catch (e) {
            message.destroy(messageReference);
            message.error('An error occurred moving the pin');
            throw e;
        } finally {
            dispatch(exitMove());
        }
    };
}

function handleMovePinClick(
    result: __esri.SceneViewHitTestResult,
    event: __esri.ViewClickEvent,
    context: ViewClickHandlerContext
): AppThunk<boolean> {
    return (dispatch, getState) => {
        const state = getState();
        const activeMovePin = selectActiveMovePin(state);
        const itemsByKey = selectLibraryItemsByKey(state);

        const graphic = result?.results?.[0]?.type === 'graphic' ? result.results[0].graphic : null;

        if (!activeMovePin) {
            if (event.button !== 2) {
                return false;
            }

            if (graphic?.layer?.id === OSM_PINS_LAYER_ID) {
                const item = graphic.attributes.libraryItemKey
                    ? itemsByKey[graphic.attributes.libraryItemKey]
                    : undefined;
                dispatch(
                    selectOsmPin({
                        displayName: item?.title ? item.title : graphic.attributes.libraryItemKey,
                        highlightLayerKey: graphic.attributes.highlightLayerKey,
                        highlightItemKey: graphic.attributes.libraryItemKey,
                        layerId: graphic.layer.id,
                        objectId: graphic.getObjectId(),
                    })
                );

                context.showMapContextMenu([event.x, event.y], (props) =>
                    OsmHighlightPinMenu({
                        ...props,
                        libraryItemKey: graphic.attributes.libraryItemKey,
                    })
                );

                return true;
            } else {
                const item = graphic?.layer.id ? itemsByKey[graphic?.layer.id] : undefined;

                if (graphic && item?.itemType === 'CsvLayer') {
                    dispatch(
                        selectCsvPin({
                            csvLayerTreeItemKey: item.key,
                            index: graphic.getObjectId() - 1,
                        })
                    );
                    context.showMapContextMenu([event.x, event.y], (props) =>
                        OsmHighlightPinMenu({
                            ...props,
                        })
                    );

                    return true;
                }
            }
            return false;
        }

        switch (activeMovePin.pinReference.type) {
            case 'highlight-pin-reference':
                dispatch(
                    moveOsmPin(
                        result,
                        activeMovePin.pinReference,
                        activeMovePin.messageReference,
                        context
                    )
                );
                return true;
            case 'csv-pin-reference':
                dispatch(
                    moveCsvPin(
                        result,
                        activeMovePin.pinReference,
                        activeMovePin.messageReference,
                        context
                    )
                );
                return true;
        }
    };
}

export function useMovePinClickHandler(): ViewClickHandler {
    const dispatch = useAppDispatch();

    return useCallback(
        (result, event, extra): boolean => dispatch(handleMovePinClick(result, event, extra)),
        [dispatch]
    );
}
